<template>
  <div class="container">
    <div class="flex flex-col justify-center items-center h-full mt-0">
      <div>
        <img src="../assets/microsoft-logo.svg" width="164" />
      </div>
      <div class="mt-4">
        <!-- <small>Sign in with your Microsoft account to continue.</small> -->
        <small>Continue with any microsoft account</small>
      </div>


      <!-- <div class="mt-10 w-full" v-if="false">
        <div v-for="account in accounts" :key="account.username"
          class="p-3 w-full border-2 border-gray-50 flex rounded-md items-center cursor-pointer user-card"
          @click="loginPopup">
           <div class="flex items-center">
            <el-skeleton v-if="loading" style="--el-skeleton-circle-size: 100px" animated>
              <template #template>
                <el-skeleton-item variant="circle" class="w-16 h-16" />
              </template>
            </el-skeleton>
            <el-avatar v-else :size="64" :src="photoURL.src" />
          </div> 
          <div class="mx-6 w-full">
            <div>
              <el-skeleton style="width: 160px" v-if="loading" :rows="0" animated />
              <b class="text-sm" v-else>{{ account.name }}</b>
            </div>
            <div>
              <el-skeleton v-if="loading" :rows="0" animated>
                <template #template>
                  <el-skeleton-item variant="text" class="w-full" />
                </template>
              </el-skeleton>
              <small v-else>{{ account.username }}</small>
            </div>
            <div class="mt-2 avr-color font-bold">
              <el-skeleton v-if="loading" :rows="0" animated>
                <template #template>
                  <el-skeleton-item variant="text" class="w-full" />
                </template>
              </el-skeleton>
              <small v-else>Continue with this account</small>
            </div>
          </div>
        </div>
        <el-divider content-position="center">or</el-divider>
        <el-button class="w-full bg-primary hover:bg-primary-500 border-0" type="primary" size="large"
          @click="loginAnotherAccount">Login with Another Account</el-button>
      </div> -->
      <div class="mt-10 mb-20 w-full">
        <el-button class="w-full bg-primary hover:bg-primary-500 border-0" type="primary" size="large"
          @click="loginRedirect">Login with Microsoft</el-button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus/es'
import { setToken } from '../utils/storage'

const dialog = ref()
const loading = ref(false)

function processMessage(arg) {
  const messageFromDialog = JSON.parse(arg.message);
  let token = messageFromDialog.accessToken
  token = token.slice(1)
  token = token.substring(0, token.length - 1)
  ElMessage.success('Login Success.')
  setToken(token)
  loading.value = false
  location.replace('/')

  dialog.value.close()
  return
}

const loginRedirect = () => {
  window.Office.context.ui.displayDialogAsync(window.location.origin + "/login.html", {
    width: 50, height: 50,
    displayInIframe: false
  },
    (result) => {
      dialog.value = result.value
      dialog.value.addEventHandler(window.Office.EventType.DialogMessageReceived, processMessage)
    }
  )
}

</script>